<template>
  <v-container class="max-container">
    <top-banner :cancel="cancel" :show-btn="true" />
    <v-card class="payment-box" flat dark color="black">
      <add-payment-form ref="paymentForm" />
      <v-btn block depressed large class="tile text-capitalize channel-button btn-add-payment" :loading="loading" @click="addPayment">Complete</v-btn>
      <div class="authcard-message">
        {{ tip_text }}<br v-if="tip_text">
        Your card details are sent to Stripe via secure SSL connection for payment processing.
        We do not store your card information in our servers.To verify your credit card we will try charge it for ${{ try_charge_amount/100 }} and immediately refund it.
      </div>
      <!-- <div class="spacer-box" /> -->

    </v-card>
    <c-snack-bar ref="csnackbar" />
  </v-container>
</template>

<script>

import TopBanner from './components/topBanner'
import PaymentMixin from '@/mixins/paymentMixin'

import logo from '@/assets/logo.png'

export default {
  name: 'AddPayment',
  components: {
    TopBanner
  },
  mixins: [PaymentMixin],
  data: () => ({
    loading: false,
    logo: logo,
    tip_text: ''
  }),
  created() {
    this.getTextByCode()
  },
  methods: {
    async goTo() {
      if (this.newUser) {
        this.$router.push({ path: '/auth/subscription' })
        return
      }
      await this.$store.dispatch('user/getInfo')
      this.$router.push({ name: 'Cards' })
    },
    cancel() {
      this.$router.push({ name: 'Cards' })
    }
  }
}
</script>
<style scoped lang="scss">

.v-card__subtitle, .v-card__text, .v-card__title{
  padding-left: 2px!important;
  padding-bottom:2px;
  padding-right: 0px;
}

.container{
  text-align: -moz-center;
  text-align: center;
  text-align: -webkit-center;
}

.payment-box{
  max-width: 400px;
}

.trial-box{
  width: 100%;
  text-align: center;
  .v-btn{
    font-weight: bold;
    letter-spacing: 0.08em;
    color: #E040FB;
    font-size: 800;
  }
}

.tip-box{
  width: 100%;
  height: 50px;
  background: #EFEBE9;
}

.channel-button {
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.card-title-text{
  color: #eae5e5!important;
}

.authcard-message{
  padding-top: 10px;
  text-align: initial;
  color: rgba(255, 255, 255, 0.7);
}

.spacer-box{
  width: 100%;
  height: 20px;
}
</style>
